import axios from 'axios';
import React, { useEffect, useState } from 'react'

export const LogsAi = () => {
    const [text, setText] = useState("");

    useEffect(() => {
      console.log("😎");
      console.log("Link:", process.env.REACT_APP_URL);
  
      axios
        .get(`${process.env.REACT_APP_URL}/api/getlogsai`)
        .then(res => {
          const reversedText = res.data
              .split('\n') // Разбиваем текст на строки
              .reverse()   // Переворачиваем массив строк
              .join('\n'); // Собираем обратно
              setText(reversedText);

      })
        .catch((err) => console.error("Error fetching logs:", err)); // Логируем ошибки
    }, []);
  
    return <div>
        <p>Logs AI</p>
        
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{text}</pre></div>;
}

export default LogsAi