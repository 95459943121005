import React, { useEffect, useState } from 'react';
import { PiNotePencilDuotone } from 'react-icons/pi';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';

import { FaEdit, FaArrowDown, FaArrowUp, FaTrash } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { deleteDishAsync, moveDish, updateMenuOptionOnServer } from '../store/slices/dishesSlice';
import { useDispatch, useSelector } from 'react-redux';
import EditDish from './EditDish';
import axios from 'axios';
import { selectDishes, updateDish } from '../store/slices/dishesSlice';

const Dish = ({
  id,
  title,
  img_url,
  price,
  output,
  description,
  cooking_time,
  calories,
  ingredients,
  tags,
  isFirstInCategory,
  isLastInCategory,
  menuoption,
  category_id,
  userId,
  long_description,
  ai_prompt
}) => {
  const [position, setPosition] = useState(); // left, center, right
  const [labelToggle, setLabelToggle] = useState(''); // left, center, right

  useEffect(() => {
    switch (menuoption) {
      case 1:
        setPosition('left');
        setLabelToggle('Основное меню и доставка');
        break;
      case 2:
        setPosition('center');
        setLabelToggle('Только основное меню');
        break;
      case 3:
        setPosition('right');
        setLabelToggle('Только доставка');
        break;
      case 4:
        setPosition('none');
        setLabelToggle('Нигде не отображать');
        break;
      default:
        break;
    }
  }, [menuoption]);

  /*  const handleClick = () => {
    let newMenuOption;

    switch (position) {
      case 'left':
        setPosition('center');
        setLabelToggle('Только основное меню');
        newMenuOption = 2;
        break;
      case 'center':
        setPosition('right');
        setLabelToggle('Только доставка');
        newMenuOption = 3;
        break;
      case 'right':
        setPosition('none');
        setLabelToggle('Нигде не отображать');
        newMenuOption = 4;
        break;
      case 'none':
        setPosition('left');
        setLabelToggle('Основное меню и доставка');
        newMenuOption = 1;
        break;
      default:
        break;
    }

    dispatch(updateMenuOptionOnServer({ id, newMenuOption }));
  }; */
  //Брать menuoption из слайса и связать с toggle. Диспатчить на сервер.

  const dispatch = useDispatch();

  const dishes = useSelector(selectDishes);

  const [showEditDish, setShowEditDish] = useState(false);

  // удаляем блюдо. Передаем в deleteDishAsync глобально доступный в тек. компоненте id. Запрос идет из redux
  function deleteDish() {
    dispatch(
      deleteDishAsync({ id: id, categoryId: category_id, img_url: img_url, user_id: userId }),
    );
  }

  function editDish() {
    setShowEditDish((prevShowEditDish) => !prevShowEditDish);
  }

  async function handleMoveUp(currentDishId) {
    const currentDish = dishes.find((dish) => dish.id === currentDishId);
    if (!currentDish || currentDish.position <= 1) return;

    const aboveDish = dishes.find(
      (dish) =>
        dish.category_id === currentDish.category_id && dish.position === currentDish.position - 1,
    );
    if (!aboveDish) return;

    // Обновляем позиции и делаем асинхронный запрос к серверу
    let updatedCurrentDish = {
      ...currentDish,
      position: currentDish.position - 1,
    };
    let updatedAboveDish = {
      ...aboveDish,
      position: aboveDish.position + 1,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateDishesPositionUp`, {
        updatedCurrentDish,
        updatedAboveDish,
      });

      if (response.data.success) {
        // Пересчитываем isFirstInCategory и isLastInCategory
        const updatedDishesInCategory = dishes
          .filter((dish) => dish.category_id === currentDish.category_id)
          .sort((a, b) => a.position - b.position);

        updatedDishesInCategory.forEach((dish, index) => {
          if (dish.id === updatedCurrentDish.id || dish.id === updatedAboveDish.id) {
            if (dish.id === updatedCurrentDish.id) {
              updatedCurrentDish.isFirstInCategory = index === 0;
              updatedCurrentDish.isLastInCategory = index === updatedDishesInCategory.length - 1;
            } else if (dish.id === updatedAboveDish.id) {
              updatedAboveDish.isFirstInCategory = index === 0;
              updatedAboveDish.isLastInCategory = index === updatedDishesInCategory.length - 1;
            }
          }
        });

        dispatch(updateDish(updatedCurrentDish));
        dispatch(updateDish(updatedAboveDish));
      } else {
        console.error('Ошибка при обновлении позиции блюда 1111:', response.data.message);
      }
    } catch (error) {
      console.error('Ошибка при обновлении позиции блюда 22222:', error);
    }
  }

  async function handleMoveDown(currentDishId) {
    const currentDish = dishes.find((dish) => dish.id === currentDishId);
    if (!currentDish) return;

    const belowDish = dishes.find(
      (dish) =>
        dish.category_id === currentDish.category_id && dish.position === currentDish.position + 1,
    );
    if (!belowDish) return;

    // Обновляем позиции и делаем асинхронный запрос к серверу
    let updatedCurrentDish = {
      ...currentDish,
      position: currentDish.position + 1,
    };
    let updatedBelowDish = {
      ...belowDish,
      position: belowDish.position - 1,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/updateDishesPositionDown`,
        {
          updatedCurrentDish,
          updatedBelowDish,
        },
      );

      if (response.data.success) {
        // Пересчитываем isFirstInCategory и isLastInCategory
        const updatedDishesInCategory = dishes
          .filter((dish) => dish.category_id === currentDish.category_id)
          .sort((a, b) => a.position - b.position);

        updatedDishesInCategory.forEach((dish, index) => {
          if (dish.id === updatedCurrentDish.id || dish.id === updatedBelowDish.id) {
            if (dish.id === updatedCurrentDish.id) {
              updatedCurrentDish.isFirstInCategory = index === 0;
              updatedCurrentDish.isLastInCategory = index === updatedDishesInCategory.length - 1;
            } else if (dish.id === updatedBelowDish.id) {
              updatedBelowDish.isFirstInCategory = index === 0;
              updatedBelowDish.isLastInCategory = index === updatedDishesInCategory.length - 1;
            }
          }
        });
        dispatch(updateDish(updatedCurrentDish));
        dispatch(updateDish(updatedBelowDish));
      } else {
        console.error('Ошибка при обновлении позиции блюда:', response.data.message);
      }
    } catch (error) {
      console.error('Ошибка при обновлении позиции блюда:', error);
    }
  }

  const positionClasses = {
    left: 'left-0',
    center: 'left-1/3',
    right: 'left-2/3',
  };

  return (
    <>
      <div className="border hover:bg-slate-200">
        <div className=" flex items-center p-4 hover:bg-slate-200">
          <div className="flex-shrink-0">
            {img_url ? (
              <img src={`${process.env.REACT_APP_URL}/${img_url}`} alt={title} className="w-20" />
            ) : (
              <div className="bg-white h-16 w-16"></div>
            )}
          </div>

          <div className="flex-grow pl-4 sm:pr-4">
            <h2 className="text-xl">{title}</h2>
            <h5 className="text-base">{price}</h5>
            <p>{description}</p>
            <p className="pt-2">{output}</p>
          </div>
          <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0 flex-shrink-0 ">
            <button className="text-gray-400 hover:text-gray-800">
              <FaEdit onClick={() => editDish(id)} />
            </button>
            {!isFirstInCategory && (
              <button
                className="text-gray-400 hover:text-gray-800"
                onClick={() => handleMoveUp(id)}>
                <FaArrowUp />
              </button>
            )}
            {!isLastInCategory && (
              <button
                className="text-gray-400 hover:text-gray-800"
                onClick={() => handleMoveDown(id)}>
                <FaArrowDown />
              </button>
            )}
            <button className="text-amber-600 hover:text-amber-800" onClick={() => deleteDish()}>
              <RiDeleteBinLine />
            </button>
          </div>
        </div>

        <div className="my-2 flex flex-col pl-4 ">
          <div>
            <p className='text-xs font-normal pb-1'>где отображать</p>
          </div>
          <div>
            <select
              className="bg-slate-100 border border-1 text-slate-800"
              value={menuoption}
              onChange={(e) => {
                const newMenuOption = parseInt(e.target.value, 10);
                dispatch(updateMenuOptionOnServer({ id, newMenuOption }));
              }}>
              <option value={1}>Основное меню и доставка</option>
              <option value={2}>Только основное меню</option>
              <option value={3}>Только доставка</option>
              <option value={4}>Нигде не отображать</option>
            </select>
          </div>
        </div>
      </div>

      {showEditDish === true && (
        <div className="ml-4 bg-cyan-50 border-4 border-dashed">
          <EditDish
            id={id}
            title={title}
            img_url={img_url}
            price={price}
            output={output}
            description={description}
            cooking_time={cooking_time}
            calories={calories}
            ingredients={ingredients}
            setShowEditDish={setShowEditDish}
            tagsDish={tags}
            category_id={category_id}
            long_description={long_description}
            ai_prompt={ai_prompt}
          />
        </div>
      )}
    </>
  );
};

export default Dish;
