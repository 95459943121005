import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import storiesSlice, {
  deleteStory,
  deleteStoryImage,
  fetchStoriesByBranchId,
  moveDownStory,
  moveUpStory,
  updateStory,
  uploadStoryImage,
} from '../store/slices/storiesSlice';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { moveStoryUpReducer } from '../store/slices/storiesSlice';
import { selectActiveBranch } from '../store/slices/branchSlice';
import Resizer from 'react-image-file-resizer';

// Story.jsx Компонент для отображения одной истории
const Story = ({
  id,
  uuid,
  heading: initialHeading,
  subheading: initialSubheading,
  duration: initialDuration,
  title: initialTitle,
  url,
  sort,
  branchId,
  content_id,
  display_order,
  isFirst,
  isLast,
}) => {
  const dispatch = useDispatch();
  const [heading, setHeading] = useState(initialHeading);
  const [subheading, setSubheading] = useState(initialSubheading);
  const [duration, setDuration] = useState(initialDuration);
  const [title, setTitle] = useState(initialDuration);

  const activeBranch = useSelector(selectActiveBranch);
  const activeBranchId = activeBranch.id;

  // Хук для принудительного обновления
  const [, forceUpdate] = useState();

  // Функция для принудительного обновления компонента
  const refresh = () => forceUpdate({});

  const handleSaveStoryChanges = () => {
    // Диспатчим thunk с текущими значениями состояния
    dispatch(updateStory({ id: content_id, heading, subheading }));
  };
  const handleSaveStoryTitleChanges = () => {
    if (initialHeading === heading) {
      return;
    }
    // Диспатчим thunk с текущими значениями состояния
    dispatch(updateStory({ id: content_id, heading, subheading }));
  };
  const handleSaveStoryTextChanges = () => {
    if (initialSubheading === subheading) {
      return;
    }
    // Диспатчим thunk с текущими значениями состояния
    dispatch(updateStory({ id: content_id, heading, subheading }));
  };

  const handleDeleteStoryImage = () => {
    dispatch(deleteStoryImage({ groupId: id, id: content_id, url }));
  };

  // Функция для обработки добавления фото
  // Функция для обработки добавления фото
  async function handleAddPhotoClick(event) {
    // Получаем выбранный файл
    const file = await CompressFile(event.target.files[0]);

    // Проверяем размер сжатого файла
    if (file.size > 2097152) {
      // 2 МБ в байтах
      alert('Файл слишком большой! Размер файла не должен превышать 2 МБ.');
      return; // Прерываем функцию, если файл слишком большой
    }

    if (file) {
      // Вызываем async thunk для загрузки изображения, передавая ID группы и выбранный файл
      dispatch(uploadStoryImage({ groupId: id, imageFile: file, id: content_id }));
    }
  }

  async function CompressFile(originalFile) {
    try {
      const image = await new Promise((resolve) => {
        Resizer.imageFileResizer(
          originalFile,
          1000, // Установите размеры, которые вы хотите здесь.
          1000,
          'PNG',
          100,
          0,
          (uri) => {
            // Convert blobUrl to File object
            // // console.log('~ сработал Resizer');
            fetch(uri)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File([blob], originalFile.name, {
                  type: 'image/png',
                  lastModified: new Date().getTime(),
                });
                resolve(file);
              })
              .catch((err) => console.error(err));
          },
          'base64',
        );
      });
      return image;
    } catch (error) {
      console.error(' Не удалось сжать файл: ', error);
    }
  }

  const handleDeleteStory = () => {
    dispatch(deleteStory({ content_id, url, groupId: id, id: content_id }));
  };

  async function handleMoveUp() {
    dispatch(moveUpStory({ groupId: id, content_id, display_order }));
    //dispatch(fetchStoriesByBranchId(activeBranchId));
    //handleSomeAction();
  }
  async function handleMoveDown() {
    dispatch(moveDownStory({ groupId: id, display_order, content_id }));
  }

  return (
    <div style={{ marginBottom: '20px' }} className="bg-teal-50 mt-1 mr-2 ml-2 p-4">
      {/*   <img src={group.content.profileImage} alt="Profile" style={{ width: 100, height: 100, borderRadius: '50%' }} /> */}
      <div className="flex flex-col">
        <div className="flex justify-end">
          <button className="text-gray-400 hover:text-gray-800" onClick={() => handleMoveUp()}>
            {!isFirst && <FaArrowUp />}
          </button>
          <button
            className="text-gray-400 hover:text-gray-800 mr-10"
            onClick={() => handleMoveDown()}>
            {!isLast && <FaArrowDown />}
          </button>

          <button
            id={content_id}
            className="ml-2  text-xs mr-1 text-red-800"
            onClick={handleDeleteStory}>
            Удалить историю
          </button>
        </div>

        <div class="text-field text-field_floating-2">
          <input
            className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
            name="story-name"
            type="text"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            onBlur={handleSaveStoryTitleChanges}
          />
          <label class="text-field__label" for="story-name">
            Заголовок
          </label>
        </div>

        <p className="text-xs mt-2">Текст</p>
        <textarea
          className="textarea-1 w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
          type="text"
          rows={3}
          value={subheading}
          onChange={(e) => setSubheading(e.target.value)}
          onBlur={handleSaveStoryTextChanges}
        />
      </div>

      <div className="flex flex-row mt-4">
        {url ? (
          <>
            <img
              src={`${process.env.REACT_APP_URL}${url}`}
              alt="Profile"
              className="story-group-profile-image w-28 ml-2 mb-4"
            />
            <div className="">
              <RiDeleteBinLine className="cursor-pointer mx-2" onClick={handleDeleteStoryImage} />
            </div>
          </>
        ) : (
          <div>
            <input
              type="file"
              onChange={handleAddPhotoClick}
              style={{ display: 'none' }}
              id={`upload-photo-${content_id}`}
            />
            <label htmlFor={`upload-photo-${content_id}`}>
             {/*  <div className="add-photo-button">Добавить фото</div> */}
            </label>
          </div>
        )}
      </div>
      {/*       <button className="border p-2 mt-1" onClick={handleSaveStoryChanges}>
        Сохранить
      </button> */}
    </div>
  );
};

export default Story;
