import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Tag from './Tag';
import { RxCross1 } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveBranch } from '../store/slices/branchSlice';
import {
  setDishes,
  selectDishes,
  deleteDishImage,
  uploadDishImageThunk,
  changeDishImage,
} from '../store/slices/dishesSlice';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiImageAdd } from 'react-icons/bi';
import { useRef } from 'react';
import { selectUserId } from '../store/slices/userSlice';
import Resizer from 'react-image-file-resizer';
import { CiImageOff } from 'react-icons/ci';

import { PiTrashThin } from 'react-icons/pi';
import FoodPhotoBank from './FoodPhotoBank';

const EditDish = ({
  id,
  title,
  img_url,
  price,
  output,
  description,
  cooking_time,
  calories,
  ingredients,
  setShowEditDish,
  category_id,
  long_description,
  tagsDish,
  ai_prompt,
}) => {
  const dispatch = useDispatch();
  const [editedTitle, setEditedTitle] = useState(title || '');
  const [editedPrice, setEditedPrice] = useState(price || '');
  const [editedAi_prompt, setEditedAi_prompt] = useState(ai_prompt || '');

  const [editedOutput, setEditedOutput] = useState(output);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedCooking_time, setEditedCooking_time] = useState(cooking_time);
  const [editedCalories, setEditedCalories] = useState(calories);
  const [editedIngredients, setEditedtIngredients] = useState(ingredients);
  /*  const [editedTags, setEditedTags] = useState(tags); */
  const [editedLong_description, setLong_description] = useState(long_description);

  const [showPhotoBank, setShowPhotoBank] = useState(false);
  const togglePhotoBank = () => setShowPhotoBank(!showPhotoBank);

  const [selectedTagId, setSelectedTagId] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedTags, setSelectedTags] = useState(tagsDish || []);

  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const tags = useSelector((state) => state.dishTags.tags);

  const [selectedCategory, setSelectedCategory] = useState('Все'); // 'Все' - это начальное значение

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const uniqueCategories = new Set(tags.map((tag) => tag.category));
    setCategories(['Все', ...uniqueCategories]);
  }, [tags]);

  function getMe() {
    console.log('~ selectedTags:', selectedTags);
    console.log('~ tagsDish:', tagsDish);
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const selectTag = (id) => {
    const tag = tags.find((t) => t.id === id);
    setSelectedTagId(id);
    setSelectedTag(tag); // Теперь этот шаг корректно обновляет selectedTag
  };

  const handleAddTag = () => {
    console.log('~ selectedTag: ', selectedTag);
    if (selectedTag && !selectedTags.find((tag) => tag.id === selectedTag.id)) {
      console.log('~ handleAddTag');
      setSelectedTags((prevTags) => [...prevTags, selectedTag]);
      closeModal(); // Закрыть модальное окно после добавления тега
    }
  };

  const handleDescriptionChange = (id, newDescription) => {
    setSelectedTags((currentTags) =>
      currentTags.map((tag) => (tag.id === id ? { ...tag, description: newDescription } : tag)),
    );
  };

  const removeTag = (id) => {
    setSelectedTags((currentTags) => currentTags.filter((tag) => tag.id !== id));
  };

  /*   const allTags = useSelector((state) => state.dishTags.tags);
  // Состояние для выбранного тега
  const [selectedTagId, setSelectedTagId] = useState(''); */

  // Обработчик изменения выбора тега

  /*   const handleTagChange = (event) => {
    const tagId = parseInt(event.target.value, 10); // Преобразуем ID тега из строки в число
    const selectedTag = allTags.find((tag) => tag.id === tagId);

    if (selectedTag) {
      // Добавляем выбранный тег в массив editedTags, если он уже не добавлен
      setEditedTags((prevTags) => {
        const isTagAlreadyAdded = prevTags.some((tag) => tag.id === tagId);
        if (!isTagAlreadyAdded) {
          return [...prevTags, selectedTag];
        }
        return prevTags;
      });
    }

    // Сброс выпадающего списка в начальное состояние
    setSelectedTagId('');
  }; */
  //console.log('editedTags: ', editedTags);

  const selectImage = async (url) => {
    setShowPhotoBank(false); // Закрыть FoodPhotoBank после выбора картинки
    console.log('~ selectImage');
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], 'downloaded.png', { type: 'image/png' });

      const resizedImage = await new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
          file,
          300, // width
          300, // height
          'PNG',
          100, // quality
          0, // rotation
          (uri) => {
            fetch(uri)
              .then((res) => res.blob())
              .then((blob) => {
                const resizedFile = new File([blob], file.name, {
                  type: 'image/png',
                  lastModified: Date.now(),
                });
                resolve(resizedFile);
              })
              .catch((err) => reject(err));
          },
          'base64', // output type
        );
      });

      setFile(resizedImage);
      setPreviewImage(URL.createObjectURL(resizedImage));
    } catch (err) {
      console.error('Error resizing image:', err);
    }
  };

  //TODO если поле приходит пустым, то появляется undefined, нужно это исключать и делать ''
  //TODO при редактировании блюда давать возможность поменять фото тоже

  const onFileChange = async (originalFile) => {
    return new Promise(async (resolve, reject) => {
      // const originalFile = event.target.files[0];
      try {
        const image = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            originalFile,
            300, // Установите размеры, которые вы хотите здесь.
            350,
            'PNG',
            100,
            0,
            (uri) => {
              fetch(uri)
                .then((res) => res.blob())
                .then((blob) => {
                  const file = new File([blob], originalFile.name, {
                    type: 'image/png',
                    lastModified: new Date().getTime(),
                  });
                  resolve(file);
                })
                .catch((err) => console.error(err));
            },
            'base64',
          );
        });
        setFile(image);
        setPreviewImage(URL.createObjectURL(image)); // После инициализации image
        return image; // Возвращаем обработанный файл

        // Здесь вы можете также обновить Redux store, если это необходимо
      } catch (err) {}
    });
  };

  const activeBranch = useSelector(selectActiveBranch);
  useEffect(() => {
    return () => {};
  }, [editedTitle]);

  function saveDishChanges() {
    // Собираем все редактируемые данные в один объект
    const updatedDish = {
      title: editedTitle,
      price: editedPrice,
      output: editedOutput,
      description: editedDescription,
      cooking_time: editedCooking_time,
      calories: editedCalories,
      ingredients: editedIngredients,
      tags: selectedTags,
      long_description: editedLong_description,
      ai_prompt: editedAi_prompt
    };

    if (file) {
      dispatch(uploadDishImageThunk({ file: file, userId, category_id, dishId: id }));
    }

    // Отправляем запрос на сервер
    axios
      .put(`${process.env.REACT_APP_URL}/api/updateDish/${id}`, updatedDish)
      .then((response) => {
        // Обрабатываем успешный ответ
        // получаем обновленные блюда с сервера
        const fetchData = async () => {
          try {
            const activeBranchId = activeBranch.id;
            const response = await axios.get(
              `${process.env.REACT_APP_URL}/api/dishes/${activeBranchId}`,
            );
            dispatch(setDishes(response.data));
            //dispatch(fetchDishTags());
          } catch (error) {
            console.error('An error occurred while fetching the dishes:', error);
          }
        };
        fetchData();
      })
      .catch((error) => {
        // Обрабатываем возможные ошибки
        console.error('Ошибка при обновлении блюда:', error);
      });
  }

  /* const handleTagRemove = (id) => {
    setEditedTags(editedTags.filter(tag => tag.id !== id));

    
  }; */

  function deleteImage() {
    //id - блюда.
    dispatch(deleteDishImage({ id, userId, categoryId: category_id }));
  }

  const fileInput = useRef(null);
  const userId = useSelector(selectUserId);

  function uploadDishImage(event) {
    const originalFile = event.target.files[0];
    onFileChange(originalFile);
  }

  function handleSaveButtonClick() {
    if (editedTitle === '') {
      alert('Введите название блюда');
      return;
    }
    if (editedPrice === '') {
      alert('Введите цену блюда');
      return;
    }
    setShowEditDish(false);
    saveDishChanges();
  }

  const imageUrl = `${process.env.REACT_APP_URL}/${img_url}`;
  return (
    <div className="ml-4">
      <div className="mt-4 font-semibold ">Редактирование блюда</div>

      <div class="text-field text-field_floating-2 mt-6 mr-3">
        <input
          name="dish-name"
          value={editedTitle}
          onChange={(e) => setEditedTitle(e.target.value)}
          placeholder="Название блюда (обязательное поле)"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12 "
        />
        <label class="text-field__label" for="dish-name">
          Название блюда (обязательно)
        </label>
      </div>

      <div class="text-field text-field_floating-2 mt-3 mr-3">
        <input
          name="price"
          type="number"
          value={editedPrice}
          onChange={(e) => setEditedPrice(e.target.value)}
          placeholder="Цена (обязательное поле)"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
        />
        <label class="text-field__label" for="price">
          Цена
        </label>
      </div>

      <div class="text-field text-field_floating-2 mt-3 mr-3">
        <input
          name="output"
          type="text"
          value={editedOutput}
          onChange={(e) => setEditedOutput(e.target.value)}
          placeholder="Выход блюда"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
        />
        <label class="text-field__label" for="output">
          Выход блюда
        </label>
      </div>

      <p className="mt-4 font-normal text-xs">Краткое описание</p>
      <textarea
        rows={5}
        type="text"
        value={editedDescription}
        onChange={(e) => setEditedDescription(e.target.value)}
        placeholder="Краткое описание"
        className="textarea-1 w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
      />
      <>
        {img_url ? (
          <div className="flex flex-row">
            <img className="h-32 mt-6" src={imageUrl} alt="Превью блюда" />
            <button
              className="mt-2 mr-2 text-white rounded-full w-6 h-6 flex items-center justify-center"
              onClick={deleteImage}>
              <RiDeleteBinLine className="text-slate-700 mt-6" />
            </button>
          </div>
        ) : (
          <div className="">
            <input type="file" onChange={uploadDishImage} />
            {previewImage && <img src={previewImage} alt="Preview" />}
            {/* <button
              className="flex items-center border-2 p-2"
              onClick={() => fileInput.current.click()}>
              <BiImageAdd className="mr-2" />
              Загрузить фото
            </button> */}
            <div className="mt-1">
              <button className=" button-s" onClick={togglePhotoBank}>
                Фотобанк
              </button>
              {showPhotoBank && (
                <FoodPhotoBank closePhotoBank={togglePhotoBank} selectImage={selectImage} />
              )}
            </div>
          </div>
        )}
      </>
      <div className="mt-4 font-semibold ">Подробное описание блюда</div>
      <div className="font-extralight text-sm ">Отображается в карточке блюда, при нажатии </div>

      <div class="text-field text-field_floating-2 mt-3 mr-3">
        <input
          name="calories"
          type="text"
          value={editedCalories}
          onChange={(e) => setEditedCalories(e.target.value)}
          placeholder="Калории"
          className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
        />
        <label class="text-field__label" for="calories">
          Калорийность
        </label>
      </div>

      <p className="mt-4 font-normal text-xs">Ингредиенты</p>
      <textarea
        rows={3}
        type="text"
        value={editedIngredients}
        onChange={(e) => setEditedtIngredients(e.target.value)}
        placeholder="Ингредиенты блюда"
        className="textarea-1 w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
      />
      <p className="mt-4 font-normal text-xs">Подробное описание блюда</p>
      <textarea
        rows={5}
        type="text"
        value={editedLong_description}
        onChange={(e) => setLong_description(e.target.value)}
        placeholder="Подробное описание блюда"
        className="textarea-1 w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
      />

      <div class="text-field text-field_floating-2 mt-3 mr-3">
        <input
          name="time"
          type="text"
          value={editedCooking_time}
          onChange={(e) => setEditedCooking_time(e.target.value)}
          placeholder="Время готовки/подачи"
          className=" text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
        />
        <label class="text-field__label" for="time">
          Время готовки/подачи
        </label>
      </div>
      <div>
        <p className="mt-6 font-normal text-xs">Подсказка для ИИ</p>
        <textarea
         rows={5}
         type="text"
         maxLength={600}
         value={editedAi_prompt}
         onChange={(e) => setEditedAi_prompt(e.target.value)}
         className="textarea-1 w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
          placeholder="На основании этой подсказки, искусственный интеллект будет выстраивать свои рекомендации по этому блюду. Рекомендуется для специфических и авторских блюд. Эта информация не отображается в Меню заведения."></textarea>
      </div>
      <div className="mt-8 font-semibold">Теги блюда</div>

      <div>
        <button onClick={openModal} className="button-s">
          <div className="flex flex-row">
            <span className="pl-4">добавить</span>
            <span className="ml-2 mr-4 text-base">
              <BiImageAdd />
            </span>
          </div>
        </button>
      </div>

      <div>
        {selectedTags.map((tag, index) => (
          <div key={tag.id || index} className="flex items-center p-2">
            <img
              src={`${process.env.REACT_APP_URL}/${tag.icon_url}`}
              alt="Icon"
              className="w-6 h-6 mr-2"
            />
            <input
              type="text"
              value={tag.description}
              onChange={(e) => handleDescriptionChange(tag.id, e.target.value)}
              className="border rounded py-1 px-2 "
            />
            <PiTrashThin className="ml-2 cursor-pointer" onClick={() => removeTag(tag.id)} />
          </div>
        ))}
      </div>

      {/*  <button className="border-2 p-2" onClick={getMe}>
        стейт
      </button> */}

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded-lg w-11/12 h-5/6 max-w-4xl flex flex-col justify-between">
            <div className="flex flex-wrap gap-2 mb-4">
              {categories.map((category) => (
                <button
                  key={category}
                  className={`px-3 py-1 rounded ${
                    selectedCategory === category ? 'bg-blue-500 text-white' : 'bg-white'
                  }`}
                  onClick={() => setSelectedCategory(category)}>
                  {category}
                </button>
              ))}
            </div>
            <div>Выберите иконку для тега</div>

            <div className="flex flex-wrap justify-start gap-2 overflow-auto p-2">
              {tags
                .filter((tag) => selectedCategory === 'Все' || tag.category === selectedCategory)
                .map((tag) => (
                  <div
                    key={tag.id}
                    className={`flex justify-center items-center p-1 h-10 ${
                      selectedTagId === tag.id ? 'bg-blue-100 border border-blue-500' : 'bg-white'
                    } cursor-pointer`}
                    onClick={() => selectTag(tag.id)}
                    style={{ aspectRatio: '1' }}>
                    <img
                      src={`${process.env.REACT_APP_URL}/${tag.icon_url}`}
                      alt="Icon"
                      className="w-6 h-6"
                    />
                  </div>
                ))}
            </div>

            <div className="flex flex-row justify-end space-x-2 mt-auto mt-3">
              <button onClick={closeModal} className="button-cancel">
                Отмена
              </button>
              <button onClick={handleAddTag} className="button-s">
                Добавить
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-10 mb-2 flex flex-row gap-3">
        <button className=" button-cancel" onClick={() => setShowEditDish(false)}>
          Отмена
        </button>
        <button
          className="button-s"
          onClick={() => {
            handleSaveButtonClick();
          }}>
          Сохранить изменения
        </button>
      </div>
    </div>
  );
};

export default EditDish;
