import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCategory,
  selectCategories,
  setPositions,
  addCategory,
} from '../store/slices/categoriesSlice';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import axios from 'axios';
import getCategories from '../utils/categoriesAPI';
import { selectActiveBranch } from '../store/slices/branchSlice';
import Dish from './Dish';
import { BsPlusSquareDotted } from 'react-icons/bs';
import { setDishes, selectDishes } from '../store/slices/dishesSlice';
import AddNewDish from './AddNewDish';
import { addNewDish } from '../store/slices/newDishSlice';
import { clearSelectedTags, fetchDishTags } from '../store/slices/dishTagsSlice';
import { selectUserId } from '../store/slices/userSlice';
import { CiCircleChevUp } from 'react-icons/ci';
//TODO если редактировать филиал, и зайти в его меню, то данные отображаются старые. надо обновлять activeBranch скорее всего

//TODO попробовать добавить Меню категорий для легкой навигации и кнопку Наверх, для возврата

//TODO Если пользователь удаляет категорию, предупреждать, что она не пуста, и сначала надо удалить все блюда из нее

//TODO проверить, соответствует ли порядок категорий на клиенте и админе
const Menu = () => {
  const dispatch = useDispatch();
  const dishes = useSelector(selectDishes);
  const tags = useSelector((state) => state.dishTags.tags);
  const newDish = useSelector((state) => state.newDish); // здесь получите все необходимые поля
  const userId = useSelector(selectUserId);

  const selectedTags = useSelector((state) => state.dishTags.selectedTags);

  // Извлекаем категории из Redux store
  const categories = useSelector(selectCategories);
  const activeBranch = useSelector(selectActiveBranch);
  const [isFormVisible, setFormVisible] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryDescription, setNewCategoryDescription] = useState('');
  const [showAddNewDish, setShowAddNewDish] = useState(false);
  const [shownCategoryId, setShownCategoryId] = useState(null);

  // Обновляем Redux и БД
  const [categoryInputs, setCategoryInputs] = useState(categories);
  const [oldCategories, setOldCategories] = useState(categories);

  useEffect(() => {
    // // console.log('~ useEffect categories');
    setCategoryInputs(categories);
    //getCategories(activeBranch.id, dispatch);
  }, [categories]);

  const handleInputChange = (id, fieldName, originalValue, newValue) => {
    // // console.log('~ функция handleInputChange');
    // Если новое значение совпадает с исходным, игнорируем эту операцию
    if (originalValue === newValue) {
      return;
    }
    // Обновляем локальное состояние
    setCategoryInputs(
      categoryInputs?.map((category) =>
        category.id === id ? { ...category, [fieldName]: newValue } : category,
      ),
    );

    // Обновляем Redux и БД
    dispatch(updateCategory({ id, fieldName, newValue }));
  };

  useEffect(() => {
    // // console.log('~ useEffect categoryInputs, dispatch');
    // Отправляем запросы на обновление позиций категорий только если позиции изменились
    categoryInputs.forEach((category) => {
      const oldCategory = oldCategories.find((oldCategory) => oldCategory.id === category.id);

      if (oldCategory && oldCategory.position !== category.position) {
        dispatch(
          updateCategory({ id: category.id, fieldName: 'position', newValue: category.position }),
        );
      }
    });
    // Обновляем список старых категорий
    setOldCategories(categoryInputs);
  }, [categoryInputs, dispatch]);

  const handleMove = async (direction, id, position) => {
    // // console.log('~ функция handleMove');
    // // console.log('~ ', categories);
    const lastPosition = categories.length;
    // // console.log('~ lastIndex = ', lastPosition);

    // // console.log('~ position =', position);

    if (direction === 'down') {
      // понижение категории
      const idxCurrentElement = categories.findIndex((category) => category.position === position);
      const idxInferiorElement = idxCurrentElement + 1;

      // проверка на наличие элемента ниже для понижения
      if (idxInferiorElement >= categories.length) {
        // // console.log('~ Current element is already at the lowest position');
        return;
      }

      const currentElement = categories[idxCurrentElement];
      const currentElementId = currentElement.id;
      const currentElementPosition = currentElement.position;

      const inferiorElement = categories[idxInferiorElement];
      const inferiorElementId = inferiorElement.id;
      const inferiorElementPosition = inferiorElement.position;

      // Поменяем местами position двух элементов

      // Create new objects with the swapped positions
      const newCurrentElement = { ...currentElement, position: inferiorElement.position };
      const newInferiorElement = { ...inferiorElement, position: currentElement.position };

      const elementsToUpdate = [
        { id: newCurrentElement.id, newValue: newCurrentElement.position },
        { id: newInferiorElement.id, newValue: newInferiorElement.position },
      ];

      axios
        .put(`${process.env.REACT_APP_URL}/api/changeMultiplePositions`, elementsToUpdate)
        .then(() => {
          // // console.log('~ запрос сработал');
          // обновить локальное состояние в Redux
          dispatch(setPositions(elementsToUpdate));
          getCategories(activeBranch.id, dispatch);
        })
        .catch((error) => {
          console.error('Error updating categories:', error);
        });
    } else if (direction === 'up') {
      // повышение категории
      const idxCurrentElement = categories.findIndex((category) => category.position === position);
      const idxSuperiorElement = idxCurrentElement - 1;
      // // console.log('~ idxSuperiorElement = ', idxSuperiorElement);
      // // console.log('~ indexCurrentElement', idxCurrentElement);
      const currentElement = categories[idxCurrentElement];
      const currentElementId = currentElement.id;
      const currentElementPosition = currentElement.position;
      // // console.log('~ currentElementId = ', currentElementId);
      // // console.log('~ currentElementPosition = ', currentElementPosition);
      const superiorElement = categories[idxSuperiorElement];
      const superiorElementId = superiorElement.id;
      const superiorElementPosition = superiorElement.position;
      // // console.log('~ superiorElementId = ', superiorElementId);
      // // console.log('~ superiorElementPosition = ', superiorElementPosition);
      // Поменяем местами position двух элементов

      // Create new objects with the swapped positions
      const newCurrentElement = { ...currentElement, position: superiorElement.position };
      const newSuperiorElement = { ...superiorElement, position: currentElement.position };

      const elementsToUpdate = [
        { id: newCurrentElement.id, newValue: newCurrentElement.position },
        { id: newSuperiorElement.id, newValue: newSuperiorElement.position },
      ];
      axios
        .put(`${process.env.REACT_APP_URL}/api/changeMultiplePositions`, elementsToUpdate)
        .then(() => {
          // // console.log('~ запрос сработал');
          // обновить локальное состояние в Redux
          dispatch(setPositions(elementsToUpdate));
          getCategories(activeBranch.id, dispatch);
        })
        .catch((error) => {
          console.error('~ Error updating categories:', error);
        });
    }
  };

  const handleSaveButtonClick = () => {
    // // console.log('~ функция handleSaveButtonClick');
    // // //console.log('~ Вызван handleSaveButtonClick');
    // Получаем максимальную позицию из текущих категорий
    let maxPosition =
      categories.length > 0 ? Math.max(...categories.map((category) => category.position)) : -1;

    const newCategory = {
      name: newCategoryName,
      description: newCategoryDescription,
      branch_id: activeBranch.id,
      position: maxPosition + 1, // добавляем новую категорию в конец списка
    };

    axios
      .post(`${process.env.REACT_APP_URL}/api/add_new_category`, newCategory)
      .then((response) => {
        // // console.log('~ Успешно добавлена новая категория:', response.data);
        // Закрываем форму и обновляем состояние
        setFormVisible(false);
        setNewCategoryName('');
        setNewCategoryDescription('');
        // TODO: Здесь нужно также обновить состояние Redux, чтобы список категорий обновился
        dispatch(addCategory(response.data));

        getCategories(activeBranch.id, dispatch);
      })
      .catch((error) => {
        console.error('~ Ошибка при добавлении новой категории:', error);
      });
  };
  const handleCancelButtonClick = () => {
    setFormVisible(false);
    setNewCategoryName('');
    setNewCategoryDescription('');
  };
  const handleAddButtonClick = () => {
    setFormVisible(true);
  };

  const handleDeleteCategory = (categoryId) => {
    // // console.log('~ удаляемая категория categoryId =', categoryId);
    axios
      .delete(`${process.env.REACT_APP_URL}/api/categories/${categoryId}`, {
        data: { branch_id: activeBranch.id },
      })
      .then((response) => {
        // Получите обновленный список категорий от сервера
        //const updatedCategories = response.data;
        // Обновите состояние Redux
        getCategories(activeBranch.id, dispatch);
      })
      .catch((error) => {
        console.error('Ошибка при удалении категории:', error);
      });
  };

  useEffect(() => {
    // // console.log('~ useEffect dispatch');
    const activeBranchId = activeBranch.id;
    // // console.log('~ activeBranchId = ', activeBranchId);
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/dishes/${activeBranchId}`,
        );
        console.log('~ 🥨 response.data: ', response.data);

        dispatch(setDishes(response.data));
        dispatch(fetchDishTags());
      } catch (error) {
        console.error('An error occurred while fetching the dishes:', error);
      }
    };
    fetchData();
  }, [dispatch, activeBranch]);

  return (
    <div>
      <button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="fixed bottom-10 right-2  text-white rounded ">
        <CiCircleChevUp className="text-3xl text-slate-500" />
      </button>
      <div className="mt-6">
        <button onClick={handleAddButtonClick} type="submit" className="button-s ">
          Добавить категорию меню
        </button>
      </div>

     

      {isFormVisible && (
        <div className="border-4 my-10 flex flex-col px-4">
          <div class="text-field text-field_floating-2 mt-6">
            <input
              value={newCategoryName}
              name="category-name"
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Название категории"
              className="w-full md:w-1/2 lg:w-1/2 xl:w-6/12  text-field__input"
            />
            <label class="text-field__label" for="category-name">
              Название категории
            </label>
          </div>
          <div class="text-field text-field_floating-2 mt-3">
            <input
              name="category-desc"
              value={newCategoryDescription}
              onChange={(e) => setNewCategoryDescription(e.target.value)}
              placeholder="Краткое описание категории"
              className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12"
            />
            <label class="text-field__label" for="category-name">
              Краткое описание категории
            </label>
          </div>
          <div className="mt-4 mb-4 flex flex-row gap-6">
            <button className="mx-4 button-cancel" onClick={handleCancelButtonClick}>
              Отмена
            </button>
            <button className=" my-3 button-s" onClick={handleSaveButtonClick}>
              Сохранить
            </button>
          </div>
        </div>
      )}

      {categoryInputs.map((category, index) => (
        <div key={category.id} className="flex flex-col border  border-slate-300 mt-6 bg-slate-100">
          <div className=" flex flex-row items-center justify-center bg-slate-100 border shadow-md border-slate-300 ">
            <div className="text-field text-field_floating-2 mt-3 w-full md:w-1/2 lg:w-1/2 xl:w-6/12 mb-3 ml-2">
              <input
                id={`category-name-${category.id}`}
                className="text-field__input w-full "
                type="text"
                defaultValue={category.name}
                name="category-name"
                onBlur={(e) =>
                  handleInputChange(category.id, 'name', category.name, e.target.value)
                }
              />
              <label class="text-field__label" for="category-name">
                Название категории
              </label>
            </div>
            <div className="flex flex-row justify-around text-xl text-slate-400">
              <div className="flex flex-row">
                {index < categoryInputs.length - 1 && (
                  <div
                    className="cursor-pointer"
                    onClick={() => handleMove('down', category.id, category.position)}>
                    <FiArrowDown />
                  </div>
                )}

                {index > 0 && (
                  <div
                    className="px-1 cursor-pointer"
                    onClick={() => handleMove('up', category.id, category.position)}>
                    <FiArrowUp />
                  </div>
                )}
              </div>
              <div className="">
                <RiDeleteBinLine
                  className="cursor-pointer mx-2"
                  onClick={() => handleDeleteCategory(category.id)}
                />
              </div>
            </div>
          </div>

          <div className="relative z-0 w-full mb-6 group mt-4 ml-3">
            <div className="text-field text-field_floating-2 mt-3">
              <input
                id={`category-description-${category.id}`}
                name='category-name'
                placeholder='Краткое описание категории'
                type="text"
                className="text-field__input w-full md:w-1/2 lg:w-1/2 xl:w-6/12 ml-3"
                defaultValue={category.description}
                onBlur={(e) =>
                  handleInputChange(
                    category.id,
                    'description',
                    category.description,
                    e.target.value,
                  )
                }
              />
              <label class="text-field__label ml-4" for="category-name">
                Краткое описание категории
              </label>
            </div>
          </div>

          <div className="flex flex-col mt-2 mb-4 ">
            {dishes
              .filter((dish) => dish.category_id === category.id)
              .sort((a, b) => a.position - b.position)
              .map((dish) => (
                <Dish
                  key={dish.id}
                  id={dish.id}
                  title={dish.title}
                  img_url={dish.img_url}
                  description={dish.description}
                  price={dish.price}
                  cooking_time={dish.cooking_time}
                  output={dish.output}
                  calories={dish.calories}
                  ingredients={dish.ingredients}
                  tags={dish.tags}
                  isFirstInCategory={dish.isFirstInCategory}
                  isLastInCategory={dish.isLastInCategory}
                  menuoption={dish.menuoption}
                  category_id={category.id}
                  userId={userId}
                  long_description={dish.long_description}
                  ai_prompt={dish.ai_prompt}
                />
              ))}
            <div>
              <div className="flex flex-row justify-between p-2 items-center mt-4">
                <button
                  className="button-s flex flex-row"
                  onClick={() => {
                    setShownCategoryId(category.id);
                    setShowAddNewDish(true); // Установка видимости формы
                    dispatch(clearSelectedTags([])); // очищаем ранее выбранные теги
                  }}>
                  <div className="flex flex-row items-center">
                    <span>Добавить блюдо</span>{' '}
                    <span className="ml-2 text-lg">
                      {' '}
                      <BsPlusSquareDotted />
                    </span>
                  </div>
                </button>
                <div className="ml-4"></div>
              </div>

              {/* ///////////////////////////////////////////////////////////////// */}
              {/* Добавление нового блюда */}
              {shownCategoryId === category.id &&
              showAddNewDish && ( // Дополнительная проверка видимости
                  <div className="bg-slate-200">
                    <div>
                      <p className="font-semibold pl-2 pt-4">Добавление нового блюда</p>
                    </div>
                    <AddNewDish />
                    <div className="flex justify-end pb-4 mr-4 gap-4">
                      <button
                        className="button-cancel"
                        onClick={() => setShowAddNewDish(false)}>
                        Отмена
                      </button>
                      <button
                        className="button-s"
                        onClick={() => {
                          const dishData = {
                            ...newDish, // данные блюда
                            categoryId: category.id, // ID категории
                            selectedTags: selectedTags,
                          };
                          // // console.log('~ dishData = ', dishData);
                          // логика сохранения
                          if (dishData.name === '') {
                            alert('Введите название блюда');
                            return;
                          }
                          if (dishData.price === '') {
                            alert('Введите цену блюда');
                            return;
                          }

                          dispatch(addNewDish(dishData));
                          setShowAddNewDish(false);
                        }}>
                        Сохранить
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Menu;
