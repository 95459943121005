import axios from "axios";
import React, { useEffect, useState } from "react";

const Logs = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    console.log("😎");
    console.log("Link:", process.env.REACT_APP_URL);

    axios
      .get(`${process.env.REACT_APP_URL}/api/getlogs`)
      .then(res => {
        const reversedText = res.data
            .split('\n') // Разбиваем текст на строки
            .reverse()   // Переворачиваем массив строк
            .join('\n'); // Собираем обратно
        setText(reversedText);
    })
      .catch((err) => console.error("Error fetching logs:", err)); // Логируем ошибки
  }, []);

  return <div>
    <p>Logs</p>
    <pre>{text}</pre></div>;
};

export default Logs;
